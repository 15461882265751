import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export function SortableItem({ item, index }) {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: item.id });
    const style = { transform: CSS.Transform.toString(transform), transition };

    return (
        <div style={{ display: "flex", gap: '10px' }}>
            <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
                <div className='sort-contener'>
                    <p>{item.sub_subject_name || item.previous_name || item.question_content}</p>
                </div>
            </div>
            <p>{index + 1}</p>
        </div>
    )
}