import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import '../inputs.css';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { SortableItem } from '../SortableItem';
import { fetchProvinces } from '../../helpers/public';
import { useCookies } from 'react-cookie';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    borderRadius: '50px',
    textAlign: 'center',
    boxShadow: 24,
    p: '20px 20px 0 20px',
    fontFamily: 'Almarai',
};

export default function ShowSubject({ handleClose, selectedRows, handleClickAlert, setMessage, getUrl, sortUrl, title }) {
    const [data, setData] = useState([]);
    const [cookies, setCookie, removeCookie] = useCookies(['token']);


    React.useEffect(() => {
        const fetchProvinces = async (setData, url) => {
            const token = cookies['token']
            try {
                const response = await axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setData(response.data);
            } catch (error) {
                console.error('Error fetching provinces:', error);
            }
        };
        fetchProvinces(setData, `${process.env.REACT_APP_URL}${getUrl}${selectedRows.id}`);
    }, []);

    const handleFormSubmit = async (e) => {
        const token = cookies['token']
        e.preventDefault();
        try {
            const response = await axios.put(`${process.env.REACT_APP_URL}${sortUrl}`, { data: data }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.status === 200) {
                setMessage("تمت العملية بنجاح")
                handleClose()
                handleClickAlert()
            }
        } catch (error) {
            setMessage("خطا في العملية")
            handleClose()
            handleClickAlert()
        }
    };

    const handelDragEnd = (e) => {
        const { active, over } = e;
        if (active.id !== over.id) {
            setData((items) => {
                const activeIndex = items.findIndex(item => item.id === active.id);
                const overIndex = items.findIndex(item => item.id === over.id);
                return arrayMove(items, activeIndex, overIndex)
            })
        }
    };

    return (
        <div>
            <Modal
                open={true}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography sx={{ fontFamily: 'Almarai' }} id="modal-modal-title" variant="h3" component="h2">
                        {title}
                    </Typography>
                    <div className="center" style={{ display: 'block' }}>
                        <form onSubmit={handleFormSubmit}>
                            <DndContext
                                collisionDetection={closestCenter}
                                onDragEnd={handelDragEnd}
                            >
                                <SortableContext
                                    items={data}
                                    strategy={verticalListSortingStrategy}
                                >
                                    {data.map((item, index) => <SortableItem key={item.id} item={item} index={index} />)}
                                </SortableContext>
                            </DndContext>
                            <div className="inputbox" style={{ marginTop: '20px' }}>
                                <button type="submit">
                                    موافق
                                </button>
                                <button type="button" onClick={handleClose}>
                                    إلغاء
                                </button>
                            </div>
                        </form>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}