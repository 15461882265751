import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { useNavigate, Link, Outlet } from 'react-router-dom';
import './layout.css'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { items } from '../constant/data';
import { useCookies } from 'react-cookie';



export default function Layout() {

    const [selectedCategory, setSelectedCategory] = React.useState("المستخدمون");

    const [cookies, setCookie, removeCookie] = useCookies(['token']);

    const navigate = useNavigate();

    React.useEffect(() => {
        const cookie = cookies['token'];
        if (!cookie) {
            navigate('/login', {replace: true});
        }
    }, [])
    

    const handelLogout = async () => {
        await removeCookie('token');
        navigate('/login', { replace: true });
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <div style={{ flexGrow: "1" }}>
                <Box
                    component="main"
                    sx={{ flexGrow: 1 }}
                >
                    <Outlet />
                </Box>
            </div>
            <div className="sidebar">
                <div className='logo-container2'>
                    <div className='ellipse1'>
                        <div className='ellipse2'>
                            <div className='groub1'>
                                <div className='groub2'>
                                    <div className='vector1'>
                                        <div className='vector2'>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <span className='title'>admin</span>
                <div className="links">
                    {items.map((item) => (
                        <Link
                            key={item.name}
                            to={item.url}
                            className={selectedCategory === item.name ? "active" : "link"}
                            onClick={() => setSelectedCategory(item.name)}
                        >
                            <div className={selectedCategory === item.name ? 'active-icon' : "container-icon"}>
                                <ArrowBackIosNewIcon className='icon' />
                            </div>
                            <span>{item.name}</span>
                        </Link>
                    ))}
                    <Link
                        className="link"
                        onClick={() => handelLogout()}
                    >
                        <div className="container-icon">
                            <ArrowBackIosNewIcon className='icon' />
                        </div>
                        <span>تسجيل الخروج</span>
                    </Link>
                </div>
            </div>
        </Box>
    );
}