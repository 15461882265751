import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import '../inputs.css';
import { CSVLink } from 'react-csv';
import { useCookies } from 'react-cookie';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    borderRadius: '50px',
    textAlign: 'center',
    boxShadow: 24,
    p: '50px 20px',
    fontFamily: 'Almarai',
};

export default function Export({ handleClose, handleClickAlert, selectedRows }) {
    const [cookies, setCookie, removeCookie] = useCookies(['token']);
    const [download, setDownload] = useState(false);
    const [data, setData] = useState([]);
    console.log(data);
    const [formData, setFormData] = useState({
        number_of_codes: false,
        number_of_wrongs: false,
        number_of_corrects: false,
        number_of_answers: false,
        start_date: "",
        end_date: "",
        user_ids: selectedRows,
    });

    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleCheckboxChange = (e) => {
        const { checked, value } = e.target;
        setFormData({
            ...formData,
            [value]: checked,
        });
    };

    const handleFormSubmit = async (e) => {
        const token = cookies['token'];
        e.preventDefault();
        setIsSubmitting(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL}/houdix/seen/admin/statistics/user-info`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.status === 200) {
                setData(response.data);
                setDownload(true);
            }
        } catch (error) {
            console.error(error.message);
        } finally {
            setIsSubmitting(false);
        }
    };

    const headers = [
        { label: 'id', key: 'id' },
        { label: 'user name', key: 'username' },
        { label: 'phone number', key: 'phone' },
        { label: 'bachelor name', key: 'bachelor' },
        { label: 'bachelorean', key: 'bachelorean' },
        { label: 'student number', key: 'cardId' },
        { label: 'student year', key: 'year' },
        { label: 'student collage', key: 'collage' },
        { label: 'student university', key: 'university' },
        { label: 'subjects', key: 'subjects' },
        { label: 'codes', key: 'codes' },
        { label: 'coupons', key: 'coupons' },
    ];
    if (formData.number_of_codes) {
        headers.push({ label: 'number_of_codes', key: 'number_of_codes' },);
    }
    if (formData.number_of_answers) {
        headers.push({ label: 'number_of_answers', key: 'number_of_answers' },);
    }
    if (formData.number_of_corrects) {
        headers.push({ label: 'number_of_corrects', key: 'number_of_corrects' },);
    }
    if (formData.number_of_wrongs) {
        headers.push({ label: 'number_of_wrongs', key: 'number_of_wrongs' },);
    }
    return (
        <div>
            <Modal
                open={true}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography sx={{ fontFamily: 'Almarai' }} id="modal-modal-title" variant="h3" component="h2">
                        تصدير
                    </Typography>
                    <div className="center">
                        <form onSubmit={handleFormSubmit}>
                            {!download ? (
                                <>
                                    <div className="inputbox">
                                        <input
                                            dir='rtl'
                                            type="text"
                                            name='start_date'
                                            value={formData.start_date}
                                            onChange={handleInputChange}
                                            placeholder='تاريخ البداية'
                                            onFocus={(e) => e.target.type = 'date'}
                                            required
                                        />
                                    </div>
                                    <div className="inputbox">
                                        <input
                                            dir='rtl'
                                            type="text"
                                            name='end_date'
                                            value={formData.end_date}
                                            onChange={handleInputChange}
                                            placeholder='تاريخ النهاية'
                                            onFocus={(e) => e.target.type = 'date'}
                                            required
                                        />
                                    </div>
                                    <div className='checkbox-box'>
                                        <div className='checkbox-child1'>
                                            <div>
                                                عدد الأكواد
                                                <input
                                                    type="checkbox"
                                                    name="wanted_info"
                                                    value="number_of_codes"
                                                    onChange={handleCheckboxChange}
                                                />
                                            </div>
                                            <div>
                                                عدد الإجابات الخاطئة
                                                <input
                                                    type="checkbox"
                                                    name="wanted_info"
                                                    value="number_of_wrongs"
                                                    onChange={handleCheckboxChange}
                                                />
                                            </div>
                                        </div>
                                        <div className='checkbox-child1'>
                                            <div>
                                                عدد الإجابات
                                                <input
                                                    type="checkbox"
                                                    name="wanted_info"
                                                    value="number_of_answers"
                                                    onChange={handleCheckboxChange}
                                                />
                                            </div>
                                            <div>
                                                عدد الإجابات الصحيحة
                                                <input
                                                    type="checkbox"
                                                    name="wanted_info"
                                                    value="number_of_corrects"
                                                    onChange={handleCheckboxChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="inputbox">
                                        <button type="submit" disabled={isSubmitting}>
                                            موافق
                                        </button>
                                        <button type="button" onClick={handleClose}>
                                            إلغاء
                                        </button>
                                    </div>
                                </>
                            ) : (
                                <div class="inputbox">
                                    <button onClick={() => { handleClose(); handleClickAlert() }} disabled={isSubmitting}>
                                        <CSVLink style={{ textDecoration: "none", color: 'black' }} data={data} headers={headers} filename={"students.csv"}>
                                            تنزيل
                                        </CSVLink>
                                    </button>
                                    <button onClick={handleClose}>
                                        إلغاء
                                    </button>
                                </div>
                            )}
                        </form>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}