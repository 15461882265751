import React, { useEffect, useState } from 'react'
import '../table.css'
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { CircularProgress } from '@mui/material';
import axios from 'axios';
import { useCookies } from 'react-cookie';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// const url = `${process.env.REACT_APP_URL}/houdix/seen/admin/bachelors`

const Chapters = () => {

    const [cookies, setCookie, removeCookie] = useCookies(['token']);


    const [message, setMessage] = useState("");
    const [open, setOpen] = React.useState(false);
    const [chapter, setChapter] = useState("الأول");
    const [isLoading, setIsLoading] = useState(true);

    const handleClickAlert = () => {
        setOpen(true);
    };

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handelChange = async (e) => {
        const token = cookies['token'];
        setIsLoading(true)
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL}/houdix/seen/admin/chapter`, { chapter : e.target.id }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.status === 200) {
                setIsLoading(false)
                setChapter(e.target.id)
                setMessage("تمت العملية بنجاح")
                handleClickAlert()
            }
        } catch (error) {
            setMessage("خطا في العملية")
            handleClickAlert()
        }
    }

    useEffect(() => {
        const fetchData = async () => {
        const token = cookies['token'];
            try {
                const response = await axios.get(`${process.env.REACT_APP_URL}/houdix/seen/admin/chapter`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setChapter(response.data.chapter)
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [open]);

    if (isLoading) {
        return (
            <div style={{ display: 'flex', height: "100vh", justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <>
            <div className='container'>
                <div className='title'>
                    <div>
                    </div>
                    <div>
                        <span className='main-title'>التحضيرية + السنوات الانتقالية</span>
                    </div>
                </div>
                <div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <div style={{ height: '597px', width: '100%', display: "flex", gap: "20px", paddingTop: "20px" }}>
                            <div id='الثاني' className={chapter === "الثاني" ? "chapter-active" : "chapter"} onClick={handelChange} >الفصل الثاني</div>
                            <div id='الأول' className={chapter === "الأول" ? "chapter-active" : "chapter"} onClick={handelChange} >الفصل الاول</div>
                        </div>
                    </div>
                </div>
            </div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseAlert} >
                <Alert onClose={handleCloseAlert} severity="success" sx={{ width: '100%', backgroundColor: '#240259' }}>
                    {message}
                </Alert>
            </Snackbar>
        </>
    )
}

export default Chapters
