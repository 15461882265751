import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import '../inputs.css';
import { fetchProvinces } from '../../helpers/public';
import { CircularProgress } from '@mui/material';
import { useCookies } from 'react-cookie';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    borderRadius: '50px',
    textAlign: 'center',
    boxShadow: 24,
    p: '50px 20px',
    fontFamily: 'Almarai',
};

export default function EditYear({ handleClose, selectedRows, handleClickAlert, setMessage }) {
    const [cookies, setCookie, removeCookie] = useCookies(['token']);

    const [isLoading, setIsLoading] = React.useState(false);
    const [data, setData] = useState({
        universities: [],
        collages: [],
    });
    const [filterData, setFilterData] = useState({
        collages: [],
    });
    const [formData, setFormData] = useState({
        university_id: selectedRows['collage.university.id'],
        collage_id: selectedRows.collage_id,
        year_name: selectedRows.year_name,
    });

    const [isSubmitting, setIsSubmitting] = useState(false);

    React.useEffect(() => {
        const fetchProvinces = async (setData, url) => {
            const token = cookies['token']
            try {
                const response = await axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setData(response.data);
            } catch (error) {
                console.error('Error fetching provinces:', error);
            }
        };
        fetchProvinces(setData, `${process.env.REACT_APP_URL}/houdix/seen/admin/get-all`);
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFormSubmit = async (e) => {
        const token = cookies['token']
        setIsLoading(true);
        e.preventDefault();
        setIsSubmitting(true);
        try {
            const response = await axios.put(`${process.env.REACT_APP_URL}/houdix/seen/admin/years/${selectedRows.id}`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.status === 200) {
                setIsLoading(false);
                setMessage("تمت العملية بنجاح")
                handleClose()
                handleClickAlert()
            }
        } catch (error) {
            setMessage("خطا في العملية")
            handleClose()
            handleClickAlert()
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleCollageChange = (e) => {
        setFilterData({
            ...filterData,
            collages: data.collages.filter(item => item.university_id == e.target.value)
        });
        handleInputChange(e);
    };

    return (
        <div>
            <Modal
                open={true}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {!isLoading ? (
                        <>
                            <Typography sx={{ fontFamily: 'Almarai' }} id="modal-modal-title" variant="h3" component="h2">
                                تعديل سنة
                            </Typography>
                            <div className="center">
                                <form onSubmit={handleFormSubmit}>
                                    <div className="inputbox">
                                        <select
                                            dir='rtl'
                                            name="university_id"
                                            onChange={handleCollageChange}
                                            onClick={handleCollageChange}
                                            value={formData.university_id}
                                            required
                                        >
                                            <option value="">اختر جامعة</option>
                                            {data.universities.map((university) => (
                                                <option key={university.id} value={university.id}>
                                                    {university.university_name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="inputbox">
                                        <select
                                            dir='rtl'
                                            name="collage_id"
                                            value={formData.collage_id}
                                            onChange={handleInputChange}
                                            required
                                        >
                                            <option value="">اختر كلية</option>
                                            {filterData.collages.map((collage) => (
                                                <option key={collage.id} value={collage.id}>
                                                    {collage.collage_name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="inputbox">
                                        <select
                                            dir='rtl'
                                            name="year_name"
                                            value={formData.year_name}
                                            onChange={handleInputChange}
                                            required
                                        >
                                            <option value="">اختر سنة</option>
                                            <option value="تحضيرية">التحضيرية</option>
                                            <option value="الاولى">السنة الأولى</option>
                                            <option value="الثانية">السنة الثانية</option>
                                            <option value="الثالثة">السنة الثالثة</option>
                                            <option value="الرابعة">السنة الرابعة</option>
                                            <option value="الخامسة">السنة الخامسة</option>
                                            <option value="السادسة">السنة السادسة</option>
                                        </select>
                                    </div>
                                    <div className="inputbox">
                                        <button type="submit" disabled={isSubmitting}>
                                            موافق
                                        </button>
                                        <button type="button" onClick={handleClose}>
                                            إلغاء
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </>
                    ) : (
                        <div style={{ display: 'flex', height: "300px", justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress />
                        </div>
                    )}
                </Box>
            </Modal>
        </div>
    );
}