import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import { CircularProgress } from '@mui/material';
import { useCookies } from 'react-cookie';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    borderRadius: "50px",
    // height: '500px',
    textAlign: 'center',
    boxShadow: 24,
    p: '50px 20px',


};

export default function EditPhoto({ handleClose, handleClickAlert, setMessage, selectedRows }) {

    const [cookies, setCookie, removeCookie] = useCookies(['token']);

    const [question, setQuestion] = React.useState(true)

    const [formData, setFormData] = React.useState(null);

    const [x, setX] = React.useState({});

    const [question_id, setQuestion_id] = React.useState({
        question_id: selectedRows.question_id,
        answer_id: selectedRows.answer_id,
    });

    const [isLoading, setIsLoading] = React.useState(false);

    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const [data, setData] = React.useState({
        questions: [],
        answers: [],
    });


    const handleInputChange = (e) => {
        setFormData(e.target.files[0]);
    };

    const handleChange1 = (e, string) => {
        const { id } = e.target;
        setQuestion_id({
            ...question_id,
            [id.slice(0, 11)]: string.id
        })
    };
    const handleChange2 = (e, string) => {
        const { id } = e.target;
        setQuestion_id({
            ...question_id,
            [id.slice(0, 9)]: string.id
        })
    };

    React.useEffect(() => {
        const fetchProvinces = async (setData, url) => {
            const token = cookies['token']
            try {
                const response = await axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setData(response.data);
                const e = response.data.questions.filter((item) => (selectedRows.question_id === item.id))
                setX(e);
            } catch (error) {
                console.error('Error fetching provinces:', error);
            }
        };
        fetchProvinces(setData, `${process.env.REACT_APP_URL}/houdix/seen/admin/get-all-for-photo`);
    }, []);
    console.log(x);

    const handleFormSubmit = async (e) => {
        const token = cookies['token'];
        setIsLoading(true);
        e.preventDefault();
        const form = new FormData();
        form.append('image', formData);
        form.append('question_id', question_id.question_id);
        form.append('answer_id', question_id.answer_id);
        console.log(form);
        setIsSubmitting(true);
        try {
            const response = await axios.put(`${process.env.REACT_APP_URL}/houdix/seen/admin/photos/${selectedRows.id}`, form, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.status === 200) {
                setIsLoading(false);
                setMessage("تمت العملية بنجاح")
                handleClose()
                handleClickAlert()
            }
        } catch (error) {
            console.log(error);
            setMessage("خطا في العملية")
            handleClose()
            handleClickAlert()
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div>
            <Modal
                open={true}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {!isLoading ? (
                        <>
                            <Typography sx={{ fontFamily: 'Almarai' }} id="modal-modal-title" variant="h3" component="h2">
                                إضافة صورة
                            </Typography>
                            <button className={!question ? "add-active" : "add-btn"} type="submit" onClick={() => { setQuestion(false); setQuestion_id({ ...question_id, question_id: null }); }} >
                                جواب
                            </button>
                            <button className={question ? "add-active" : "add-btn"} type="button" onClick={() => { setQuestion(true); setQuestion_id({ ...question_id, answer_id: null }); }}>
                                سؤال
                            </button>
                            <div className="center">
                                <form onSubmit={handleFormSubmit}>
                                    <div className="inputbox">
                                        <input
                                            type="file"
                                            name="image"
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                    {question ? (
                                        // <div className="inputbox">
                                        //     <select
                                        //         dir='rtl'
                                        //         name="question_id"
                                        //         onChange={handleChange}
                                        //         required
                                        //     >
                                        //         <option value="">اختر سؤال</option>
                                        //         {data.questions.map((item) => (
                                        //             <option key={item.id} value={item.id}>
                                        //                 {item.label}
                                        //             </option>
                                        //         ))}
                                        //     </select>
                                        // </div>
                                        <div>
                                            <Autocomplete
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            border: '4px solid #240259',
                                                            borderRadius: '10px',
                                                            height: '65px' // Remove the outer border
                                                        },
                                                        '&:hover fieldset': {
                                                            border: '4px solid #240259', // Remove the hover border
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            border: '4px solid #240259', // Remove the focused border
                                                        },
                                                    },
                                                    width: "350px"
                                                }}
                                                disablePortal
                                                options={data.questions}
                                                renderInput={(params) => <TextField dir='rtl' {...params} label="السؤال" />}
                                                onChange={handleChange1}
                                                id="question_id"
                                            />
                                        </div>
                                    ) : (
                                        // <div className="inputbox">
                                        //     <select
                                        //         dir='rtl'
                                        //         name="answer_id"
                                        //         onChange={handleChange}
                                        //         required
                                        //     >
                                        //         <option value="">اختر جواب</option>
                                        //         {data.answers.map((item) => (
                                        //             <option key={item.id} value={item.id}>
                                        //                 {item.label}
                                        //             </option>
                                        //         ))}
                                        //     </select>
                                        // </div>
                                        <div>
                                            <Autocomplete
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            border: '4px solid #240259',
                                                            borderRadius: '10px',
                                                            height: '65px' // Remove the outer border
                                                        },
                                                        '&:hover fieldset': {
                                                            border: '4px solid #240259', // Remove the hover border
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            border: '4px solid #240259', // Remove the focused border
                                                        },
                                                    },
                                                    width: "350px"
                                                }}
                                                disablePortal
                                                options={data.answers}
                                                renderInput={(params) => <TextField dir='rtl' {...params} label="الجواب" />}
                                                onChange={handleChange2}
                                                id="answer_id"
                                            />
                                        </div>
                                    )}
                                    <div className="inputbox" style={{ marginTop: "20px" }}>
                                        <button type="submit" disabled={isSubmitting}>
                                            موافق
                                        </button>
                                        <button type="button" onClick={handleClose}>
                                            إلغاء
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </>
                    ) : (
                        <div style={{ display: 'flex', height: "300px", justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress />
                        </div>
                    )}
                </Box>
            </Modal>
        </div>
    );
}