import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import '../inputs.css';
import { CircularProgress, MenuItem, TextField } from '@mui/material';
import { useCookies } from 'react-cookie';
import { MathJaxContext, MathJax } from 'better-react-mathjax';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 860,
    bgcolor: 'background.paper',
    borderRadius: '50px',
    // height: '500px',
    textAlign: 'center',
    boxShadow: 24,
    p: '25px 20px',
    fontFamily: 'Almarai',
};

export default function EditQuestion({ handleClose, selectedRows, handleClickAlert, setMessage }) {
    const [cookies, setCookie, removeCookie] = useCookies(['token']);

    const [formData, setFormData] = useState({
        sub_subject_ids: [],
        previous_ids: [],
        answer: [],
    });

    console.log(selectedRows);

    const [data, setData] = useState({
        previous: [],
        sub_subjects: [],
    });
    const [question, setQuestion] = useState({});

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        setQuestion({
            ...question,
            [name]: value,
        });
    };
    const handleInputSubjectsChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: typeof value === "string" ? value.split(',') : value,
        });
        setQuestion({
            ...question,
            [name]: typeof value === "string" ? value.split(',') : value,
        });
    };

    const handleAnswerChange = (e) => {
        const { name, value } = e.target;
        const answerArray = formData.answer;
        const newAnswer = answerArray.map((item) => {
            if (item.id == e.target.id) {
                item.answer_content = value
            }
            return item
        });
        setFormData({
            ...formData,
            answer: newAnswer
        })
        setQuestion({
            ...question,
            answer: newAnswer
        });
    };
    const handleCheckboxChange = (e) => {
        const { checked, value } = e.target;
        const answerArray = formData.answer;
        const newAnswer = answerArray.map((item) => {
            if (item.id == e.target.id) {
                item.correctness = checked
            }
            return item
        });
        setFormData({
            ...formData,
            answer: newAnswer
        })
        setQuestion({
            ...question,
            answer: newAnswer
        });
    };

    const handleCheckboxChangeForISRoll = (e) => {
        const { checked, value } = e.target;
        setQuestion({
            ...question,
            is_rollable: checked
        });
    };

    React.useEffect(() => {
        const fetchProvinces = async (setData, url) => {
            const token = cookies['token']
            try {
                const response = await axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setData(response.data);
            } catch (error) {
                console.error('Error fetching provinces:', error);
            }
        };
        fetchProvinces(setData, `${process.env.REACT_APP_URL}/houdix/seen/admin/get-all-for-question`);
        // fetchProvinces(setCode, `${process.env.REACT_APP_URL}/houdix/seen/admin/codes/${selectedRows.id}`);
        const fetchProvinces2 = async () => {
            const token = cookies['token']
            try {
                const response = await axios.get(`${process.env.REACT_APP_URL}/houdix/seen/admin/questions/${selectedRows.id}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setFormData({
                    ...formData,
                    sub_subject_ids: response.data.sub_subject_questions.map(item => item.sub_subject.id),
                    previous_ids: response.data.previous_questions.map(item => item.previous.id),
                    answer: response.data.answer

                })
                setIsLoading(false);
                setQuestion(response.data);
            } catch (error) {
                console.log(error);
                // console.error('Error fetching provinces:', error);
            }
        };
        fetchProvinces2();
    }, []);

    const handleFormSubmit = async (e) => {

        const token = cookies['token'];

        setIsLoading(true);

        e.preventDefault();

        setIsSubmitting(true);

        try {
            const response = await axios.put(`${process.env.REACT_APP_URL}/houdix/seen/admin/questions/${selectedRows.id}`, question, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.status === 200) {
                setIsLoading(false);
                setMessage("تمت العملية بنجاح")
                handleClose()
                handleClickAlert()
            }
        } catch (error) {
            setMessage("خطا في العملية")
            handleClose()
            handleClickAlert()
            console.log(error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div>
            <Modal
                open={true}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {!isLoading ? (
                        <>
                            <Typography sx={{ fontFamily: 'Almarai' }} id="modal-modal-title" variant="h3" component="h2">
                                تعديل سؤال
                            </Typography>
                            <div className="center" style={{ paddingTop: '85px', paddingBottom: '0px' }}>
                                <form onSubmit={handleFormSubmit}>
                                    <div className='input-contener'>
                                        <div className="inputbox">
                                            <textarea
                                                // dir='rtl'
                                                rows={1}
                                                // type="text"
                                                name="question_notes"
                                                value={question.question_notes}
                                                onChange={handleInputChange}
                                            />
                                            <span style={{ top: "-30px",fontSize: "17px", color: "black" }}>التفسير</span>
                                            {/* <span style={{ top: "-30px",fontSize: "17px", color: "black" }}><MathJax>{question.question_notes}</MathJax></span> */}
                                        </div>
                                        <div className="inputbox">
                                            <input
                                                dir='rtl'
                                                type="text"
                                                name="question_content"
                                                value={question.question_content}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            <span>السؤال</span>
                                        </div>
                                        {/* <div className="inputbox">
                                            <select
                                                dir='rtl'
                                                name="previous_id"
                                                onChange={handleInputChange}
                                                value={question.previous_id}
                                            >
                                                <option value="">اختر دورة</option>
                                                {data.previous.map((previous) => (
                                                    <option key={previous.id} value={previous.id}>
                                                        {`${previous?.subject?.subject_name} ${previous.previous_name} `}
                                                    </option>
                                                ))}
                                            </select>
                                        </div> */}
                                        <div className="inputbox">
                                            <TextField
                                                dir='rtl'
                                                select
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            border: '4px solid #240259',
                                                            borderRadius: '10px',
                                                            height: '65px' // Remove the outer border
                                                        },
                                                        '&:hover fieldset': {
                                                            border: '4px solid #240259', // Remove the hover border
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            border: '4px solid #240259', // Remove the focused border
                                                        },
                                                    },
                                                }}
                                                name='previous_ids'
                                                label='الدورة'
                                                value={formData.previous_ids}
                                                className='material'
                                                onChange={handleInputSubjectsChange}
                                                SelectProps={{
                                                    multiple: true,
                                                }}
                                            >
                                                {data.previous.map((item) => (
                                                    <MenuItem
                                                        key={item.id}
                                                        value={item.id}
                                                    >
                                                        {item.id} - {`${item?.subject?.subject_name} ${item?.previous_name} `}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                        <div className="inputbox">
                                            <TextField
                                                dir='rtl'
                                                select
                                                required
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            border: '4px solid #240259',
                                                            borderRadius: '10px',
                                                            height: '65px' // Remove the outer border
                                                        },
                                                        '&:hover fieldset': {
                                                            border: '4px solid #240259', // Remove the hover border
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            border: '4px solid #240259', // Remove the focused border
                                                        },
                                                    },
                                                }}
                                                name='sub_subject_ids'
                                                label='التصنيف'
                                                value={formData.sub_subject_ids}
                                                className='material'
                                                onChange={handleInputSubjectsChange}
                                                SelectProps={{
                                                    multiple: true,
                                                }}
                                                placeholder='sadasdasd'
                                            >
                                                {data.sub_subjects.map((item) => (
                                                    <MenuItem
                                                        key={item.id}
                                                        value={item.id}
                                                    >
                                                        {item.sub_subject_name} - {item.id}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                        {formData?.answer?.map((item, index) => (
                                            <>
                                                <div key={item.id} className="inputbox">
                                                    <input
                                                        id={item.id}
                                                        dir='rtl'
                                                        type="text"
                                                        name={`answer ${index}`}
                                                        value={item.answer_content}
                                                        onChange={handleAnswerChange}
                                                    />
                                                    <span>{`الجواب ${index + 1}`}</span>
                                                    <input
                                                        style={{ top: '-25px', left: '80px', }}
                                                        id={item.id}
                                                        type="checkbox"
                                                        value="correctness"
                                                        onChange={handleCheckboxChange}
                                                        checked={item.correctness}
                                                    />
                                                </div>
                                            </>
                                        ))}
                                        <div className="inputbox" style={{
                                            borderRadius: "8px",
                                            border: "4px solid #240259",
                                            height: "58px"
                                        }}>
                                            <input
                                                style={{ top: '15px', left: '30px', }}
                                                id={question.id}
                                                type="checkbox"
                                                value="is_rollable"
                                                onChange={handleCheckboxChangeForISRoll}
                                                checked={question.is_rollable}
                                            />
                                            <span style={{
                                                top: "57px",
                                                left: "225px",
                                            }}>قابل للخلط</span>
                                        </div>
                                    </div>
                                    <div className="inputbox">
                                        <button type="submit" disabled={isSubmitting}>
                                            موافق
                                        </button>
                                        <button type="button" onClick={handleClose}>
                                            إلغاء
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </>
                    ) : (
                        <div style={{ display: 'flex', height: "300px", justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress />
                        </div>
                    )}
                </Box>
            </Modal >
        </div >
    );
}