import * as React from 'react';
import Login from './pages/Login';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Layout from './components/Layout';
import Collages from './components/collages/Collages';
import Subjects from './components/subjects/Subjects';
import Codes from './components/codes/Codes';
import Link from './components/links/Links';
import Coupons from './components/coupons/Coupons';
import Students from './components/students/Students';
import Universities from './components/universities/Universities';
import Courses from './components/courses/Courses';
import Questions from './components/questions/Questions';
import Years from './components/years/Years';
import Bachelors from './components/bachelors/Bachelors';
import SubSubjects from './components/sub-subjects/SubSubjects';
import SubSubjects2 from './components/sub-subjects2/SubSubjects2';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import Chapters from './components/chapters/Bachelors';
import Photos from './components/photos/Photos';
import { MathJaxContext, MathJax } from 'better-react-mathjax';

export default function App() {

    const [cookies, setCookie, removeCookie] = useCookies(['token']);
    const navigate = useNavigate();

    React.useEffect(() => {
        const token = cookies['token']
        const fetch = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_URL}/houdix/seen/admin/auth`, null, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (response.status === 200) {
                    return
                }
            } catch (error) {
                if (error) {
                    navigate('/login', { replace: true });
                }
            }
        };
        fetch();
    }, []);

    return (
        <MathJaxContext>
            {/* <MathJax>{"when \\(a \\ne 0\\), there are tow solutions to \\(ax^2 + bx + c = 0\\) and the are \\( x = { -b \\pm \\sqrt{b^2-4ac} \\over 2a}. \\)"}</MathJax> */}
            <Routes>
                <Route path="/" element={(<Layout />)}>
                    <Route path="" element={<Students />} />
                    <Route path="/sub-subjects" element={<SubSubjects />} />
                    <Route path="/sub-subjects2" element={<SubSubjects2 />} />
                    <Route path="/years" element={<Years />} />
                    <Route path="/links" element={<Link />} />
                    <Route path="/bachelors" element={<Bachelors />} />
                    <Route path="/coupons" element={<Coupons />} />
                    <Route path="/university" element={<Universities />} />
                    <Route path="/collages" element={<Collages />} />
                    <Route path="/subjects" element={<Subjects />} />
                    <Route path="/questions" element={<Questions />} />
                    <Route path="/codes" element={<Codes />} />
                    <Route path="/courses" element={<Courses />} />
                    <Route path="/chapters" element={<Chapters />} />
                    <Route path="/photos" element={<Photos />} />
                </Route>
                <Route path="/login" element={<Login />} />
            </Routes>
        </MathJaxContext>
    );
}