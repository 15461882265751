import React from 'react'
import { Page, Text, Image, View, Document, StyleSheet, Font } from '@react-pdf/renderer';
import myFont from './codes/fonts/Almarai-Regular.ttf'

Font.register({ family: 'cairo', src: myFont });
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#fff',
        justifyContent: "center",
        alignItems: "center",
    },
    image: {
        width: "160px",
        height: "150px",
    },
    section: {
        margin: 10,
        width: "300px",
        // padding: 10,
        width: "135px",
        // flexGrow: 1,
        // backgroundColor: "red",
        justifyContent: 'center',
        alignItems: 'center',
    },
    section1: {
        lineHeight: "3px",
        justifyContent: 'center',
        alignItems: 'center',
    },
    qrcodeContainer: {
        textAlign: 'center',
        fontFamily: 'cairo',
        fontSize: "7px"
    },
    qrcodeContainer2: {
        textAlign: 'center',
        // fontFamily: 'cairo',
        fontSize: "7px"
    },
});

const QrCode = ({ qrCodeValues }) => {

    return (
        <Document>
            {qrCodeValues.map((item, index) => (
                <Page key={index} size="C8" style={styles.page}>
                    <View style={styles.section}>
                        <View style={styles.section1}>
                            <View style={styles.qrcodeContainer}>
                                <Text>{item.name}</Text>
                            </View>
                            <View style={styles.qrcodeContainer}>
                                <Text>{item.level}</Text>
                            </View>
                        </View>
                        {/* <View style={styles.qrcodeContainer}>
                            <Text>{item.subjects}</Text>
                        </View> */}
                        <View style={styles.qrcodeContainer}>
                            <Image style={styles.image} src={item.codeURL} />
                        </View>
                        <View style={styles.qrcodeContainer2}>
                            <Text>{item.QRCode}</Text>
                        </View>
                    </View>
                </Page>
            ))}
        </Document>
    )
}

export default QrCode
