import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import '../inputs.css';
import { CircularProgress } from '@mui/material';
import { useCookies } from 'react-cookie';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    borderRadius: '50px',
    textAlign: 'center',
    boxShadow: 24,
    p: '50px 20px',
    fontFamily: 'Almarai',
};

export default function EditCoupons({ handleClose, handleClickAlert, selectedRows, setMessage }) {

    const [cookies, setCookie, removeCookie] = useCookies(['token']);


    // const [paddingTop, setPaddingTop] = useState('320px')
    const [isLoading, setIsLoading] = useState(false);
    // const [data, setData] = useState({
    //     universities: [],
    //     collages: [],
    //     years: [],
    //     bachelors: [],
    //     subjects: [],
    // });
    // const [filterData, setFilterData] = useState({
    //     universities: [],
    //     collages: [],
    //     years: [],
    //     subjects: [],
    // });
    const [formData, setFormData] = useState({
        coupon_content: selectedRows.coupon_content,
        coupon_notes: selectedRows.coupon_notes,
        expiry_time: selectedRows.expiry_time,
        limit: selectedRows.limit
    });

    const [isSubmitting, setIsSubmitting] = useState(false);

    // React.useEffect(() => {
    //     fetchProvinces(setData, 'http://localhost:8080/houdix/seen/admin/get-all');
    // }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFormSubmit = async (e) => {
        const token = cookies['token'];
        setIsLoading(true);
        e.preventDefault();
        setIsSubmitting(true);
        try {
            const response = await axios.put(`${process.env.REACT_APP_URL}/houdix/seen/admin/coupons/${selectedRows.id}`, formData,  {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.status === 200) {
                setIsLoading(false)
                setMessage('تمت العملية بنجاح')
                handleClose()
                handleClickAlert()
            }
        } catch (error) {
            setMessage('خطا في العملية')
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div>
            <Modal
                open={true}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {!isLoading ? (
                        <>
                            <Typography sx={{ fontFamily: 'Almarai' }} id="modal-modal-title" variant="h3" component="h2">
                                تعديل كود
                            </Typography>
                            <div className="center" style={{ paddingTop: "40px" }}>
                                <form onSubmit={handleFormSubmit}>
                                    <div className="inputbox">
                                        <input
                                            dir='rtl'
                                            type="text"
                                            name="coupon_content"
                                            value={formData.coupon_content}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <span>الرمز</span>
                                    </div>
                                    <div className="inputbox">
                                        <input
                                            dir='rtl'
                                            type="text"
                                            name="coupon_notes"
                                            value={formData.coupon_notes}
                                            onChange={handleInputChange}
                                        />
                                        <span>ملاحظة</span>
                                    </div>
                                    <div className="inputbox">
                                        <input
                                            dir='rtl'
                                            type="number"
                                            name="expiry_time"
                                            value={formData.expiry_time}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <span>المدة</span>
                                    </div>
                                    <div className="inputbox">
                                        <input
                                            dir='rtl'
                                            type="number"
                                            name="limit"
                                            value={formData.limit}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <span>الحد</span>
                                    </div>
                                    <div className="inputbox">
                                        <button type="submit" disabled={isSubmitting}>
                                            موافق
                                        </button>
                                        <button type="button" onClick={handleClose}>
                                            إلغاء
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </>
                    ) : (
                        <div style={{ display: 'flex', height: "300px", justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress />
                        </div>
                    )}
                </Box>
            </Modal>
        </div>
    );
}