import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import '../inputs.css';
import { CircularProgress } from '@mui/material';
import { useCookies } from 'react-cookie';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    borderRadius: '50px',
    // height: '500px',
    textAlign: 'center',
    boxShadow: 24,
    p: '50px 20px',
    fontFamily: 'Almarai',
};

export default function EditUniversity({ handleClose, selectedRows, handleClickAlert, setMessage }) {
    const [cookies, setCookie, removeCookie] = useCookies(['token']);

    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        url: selectedRows.url,
        description: selectedRows.description,
    });

    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFormSubmit = async (e) => {
        const token = cookies['token'];

        e.preventDefault();
        setIsLoading(true);
        setIsSubmitting(true);

        try {
            const response = await axios.put(`${process.env.REACT_APP_URL}/houdix/seen/admin/links/${selectedRows.id}`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.status === 200) {
                setIsLoading(false);
                setMessage("تمت العملية بنجاح")
                handleClose()
                handleClickAlert()
            }
        } catch (error) {
            setMessage("خطا في العملية")
            handleClose()
            handleClickAlert()
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div>
            <Modal
                open={true}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {!isLoading ? (
                        <>
                            <Typography sx={{ fontFamily: 'Almarai' }} id="modal-modal-title" variant="h3" component="h2">
                                تعديل رابط
                            </Typography>
                            <div class="center" style={{ paddingTop: "40px" }}>
                                <form onSubmit={handleFormSubmit}>
                                    <div class="inputbox">
                                        <input
                                            // dir='rtl'
                                            type="text"
                                            name="url"
                                            value={formData.url}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <span>الرابط</span>
                                    </div>
                                    <div class="inputbox">
                                        <input
                                            dir='rtl'
                                            type="text"
                                            name="description"
                                            value={formData.description}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <span>الوصف</span>
                                    </div>
                                    <div class="inputbox">
                                        <button type="submit" disabled={isSubmitting}>
                                            موافق
                                        </button>
                                        <button type="button" onClick={handleClose}>
                                            الغاء
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </>
                    ) : (
                        <div style={{ display: 'flex', height: "300px", justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress />
                        </div>
                    )}
                </Box>
            </Modal>
        </div>
    );
}