import React, { useEffect, useState } from 'react'
import '../table.css'
import { DataGrid } from '@mui/x-data-grid'
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import AddYear from './AddYear';
import EditYear from './EditYear';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { CircularProgress } from '@mui/material';
import { yearsDataGrid } from '../../constant/dataGrid';
import CircleIcon from '@mui/icons-material/Circle';
import {
    formatCreatedAt,
    fetchData,
    // handleDeleteActionClick,
    handleAddOpen,
    handleEditOpen,
    handleEditClose,
    handleAddClose
} from '../../helpers/public';
import axios from 'axios';
import { useCookies } from 'react-cookie';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const messages = {
    a: "الرجاء اختيار سنة",
    c: "هل انت متاكد انك تريد الحذف",
    b: 'لا تستطيع الحذف السنة تحتوي على طلاب'
}
const url = `${process.env.REACT_APP_URL}/houdix/seen/admin/years`

const Years = () => {

    const [cookies, setCookie, removeCookie] = useCookies(['token']);

    const [message, setMessage] = useState("")
    const [open, setOpen] = React.useState(false);
    const [addOpen, setAddOpen] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [rows, setRows] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // fetchData(url, setRows, setIsLoading)
        const token = cookies['token']
        // fetchData(url, setRows, setIsLoading, cookies);
        const fetchData = async (url, setRows, setIsLoading, setCsvRows, token) => {
            try {
                const response = await axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setRows(response.data);
                setIsLoading(false);
                if (setCsvRows) {
                    setCsvRows(response.data)
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData(url, setRows, setIsLoading, cookies, token)
    }, [open]);

    const handleAddActionClick = () => {
        handleAddOpen(setAddOpen);
    };

    const handleEditActionClick = (row) => {
        setSelectedItem(row)
        handleEditOpen(setEditOpen);
    };

    const handleClickAlert = () => {
        setOpen(true);
    };

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleToggleActionClick = () => {
        const token = cookies['token']
        console.log(token);
        if (selectedRows.length === 0) {
            alert('اختر عنصر');
            return;
        }
        const confirmation = window.confirm('هل انت متاكد');
        if (confirmation) {
            axios
                .put(`${process.env.REACT_APP_URL}/houdix/seen/admin/years/toggle`, {
                    ids: selectedRows
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    if (response.status === 200) {
                        setMessage("تمت العملية بنجاح")
                        handleClickAlert()
                    }
                })
                .catch((error) => {
                    console.error(error)
                    setMessage("الرجاء اختيار السنة الصحيحة");
                    handleClickAlert()
                });
        }
    };

    const handleDeleteActionClick = (selectedRows, setMessage, handleClickAlert, messages, url) => {
        const token = cookies['token']
        if (selectedRows.length === 0) {
            alert(messages.a);
            return;
        }
        const confirmation = window.confirm(messages.c);
        if (confirmation) {
            axios
                .delete(url, {
                    data: { ids: selectedRows },
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    if (response.status === 200) {
                        setMessage("تمت العملية بنجاح")
                        handleClickAlert()
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setMessage(messages.b);
                    handleClickAlert()
                });
        }
    };

    if (isLoading) {
        return (
            <div style={{ display: 'flex', height: "100vh", justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <>
            <div className='container'>
                <div className='title'>
                    <div>
                        <button onClick={handleAddActionClick}>إضافة</button>
                        {selectedRows.length !== 0 && <button onClick={() => { handleDeleteActionClick(selectedRows, setMessage, handleClickAlert, messages, url) }}>حذف</button>}
                        <button onClick={handleToggleActionClick}>تعديل</button>
                    </div>
                    <div>
                        <span className='main-title'>السنوات</span>
                    </div>
                </div>
                <div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <div style={{ height: 'calc(100vh - 130px)', width: '100%' }}>
                            <DataGrid
                                sx={{ ".MuiDataGrid-columnHeaderTitle": { fontWeight: "700" }, ".MuiSvgIcon-root": { color: "#240259" }, '.MuiTablePagination-root': { direction: 'ltr' } }}
                                hideFooter={false}
                                rows={rows}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 9,
                                        },
                                    },
                                }}
                                columns={yearsDataGrid(formatCreatedAt, ModeEditIcon, handleEditActionClick, CircleIcon)}
                                checkboxSelection
                                pageSizeOptions={[9]}
                                onRowSelectionModelChange={itm => setSelectedRows(itm)}
                                disableRowSelectionOnClick
                                getRowClassName={(params) =>
                                    params.indexRelativeToCurrentPage % 2 === 0 ? 'odd' : 'even'
                                }
                                style={{ border: '5px solid #DFDFDF', borderRadius: '32px 32px 0 0 ', direction: "rtl", borderBottom: 'none', backgroundColor: "#FFFFFF" }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {addOpen && <AddYear handleClose={() => handleAddClose(setAddOpen)} handleClickAlert={handleClickAlert} setMessage={setMessage} />}
            {editOpen && <EditYear handleClose={() => handleEditClose(setEditOpen)} selectedRows={selectedItem} handleClickAlert={handleClickAlert} setMessage={setMessage} />}
            <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseAlert} >
                <Alert onClose={handleCloseAlert} severity="success" sx={{ width: '100%', backgroundColor: '#240259' }}>
                    {message}
                </Alert>
            </Snackbar>
        </>
    )
}

export default Years
